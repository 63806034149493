import { Box, ThemeProvider, createTheme } from "@mui/material";

/* eslint-disable no-undef */
const { useEffect, useRef } = require("react");

const HereMap = (props) => {
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const group = useRef(null);
  const { userPosition, destinyPosition, permission } = props;

  useEffect(
    () => {
      // Check if the map object has already been created
      if (!map.current) {
        // Create a platform object with the API key
        platform.current = new H.service.Platform({
          apikey: "EydrrWrhCH5z69nJ4QuQZjIBhe-OASKP8rd60FzPx4s",
        });
        // Create a new Raster Tile service instance
        const rasterTileService = platform.current.getRasterTileService({
          queryParams: {
            style: "explore.day",
          },
        });
        // Creates a new instance of the H.service.rasterTile.Provider class
        // The class provides raster tiles for a given tile layer ID and pixel format
        const rasterTileProvider = new H.service.rasterTile.Provider(
          rasterTileService
        );
        // Create a new Tile layer with the Raster Tile provider
        const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);
        // Create a new map instance with the Tile layer, center and zoom level
        const newMap = new H.Map(mapRef.current, rasterTileLayer, {
          pixelRatio: window.devicePixelRatio,
          zoom: 14,
          center: userPosition
        });

        const newGroup = new H.map.Group();
        newGroup.addObjects([]);
        newMap.addObject(newGroup);

        // Add panning and zooming behavior to the map
        const behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(newMap)
        );

        // Set the map object to the reference
        map.current = newMap;
        group.current = newGroup;
      }

      map.current.setCenter(userPosition, false)

      function calculateRoute(platform, map, group, start, destination) {
        var svgMarkup =
          '<svg width="18" height="18" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<circle cx="8" cy="8" r="8" ' +
          'fill="#1b468d" stroke="white" stroke-width="1" />' +
          "</svg>",
          dotIcon = new H.map.Icon(svgMarkup, {anchor: {x:8, y:8}})

        function routeResponseHandler(response) {
          const sections = response.routes[0].sections;
          const lineStrings = [];
          sections.forEach((section) => {
            // convert Flexible Polyline encoded string to geometry
            let allMatrizCoords = [];
            const coordsRoute = H.geo.LineString.fromFlexiblePolyline(
              section.polyline
            ).S;
            coordsRoute.map((item, index) => {
              if (item === 0) {
                allMatrizCoords.push({
                  lat: coordsRoute[index - 2],
                  lng: coordsRoute[index - 1],
                });
              }
              return item;
            });
            // console.log(allMatrizCoords[0]);
            let matrizCoordsSelected = [];
            for (let i = 0; i < allMatrizCoords.length; i += 25) {
              matrizCoordsSelected.push(allMatrizCoords[i]);
            }
            // console.log(matrizCoordsSelected);
            let arrayCoordsToApi = [];
            matrizCoordsSelected.map((item) => {
              arrayCoordsToApi.push(item.lat);
              arrayCoordsToApi.push(item.lng);
            });
            localStorage.setItem(
              "coordsRouteValues",
              JSON.stringify(matrizCoordsSelected)
            );
            lineStrings.push(
              H.geo.LineString.fromFlexiblePolyline(section.polyline)
            );
          });
          const multiLineString = new H.geo.MultiLineString(lineStrings);
          const bounds = multiLineString.getBoundingBox();

          // Create the polyline for the route
          const routePolyline = new H.map.Polyline(multiLineString, {
            style: {
              lineWidth: 5,
            },
          });

          // Remove all the previous map objects, if any
          map.removeObjects(map.getObjects());
          group = new H.map.Group();
          // Add the polyline to the map
          map.addObject(routePolyline);
          group.addObjects([
            routePolyline,
            // Add a marker for the user
            new H.map.Marker(start, { icon: dotIcon }),
            // Add a marker for the selected restaurant
            new H.map.Marker(destination),
          ]);
          map.addObject(group);
          map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
          });
        }

        // Get an instance of the H.service.RoutingService8 service
        const router = platform.getRoutingService(null, 8);

        // Define the routing service parameters
        const routingParams = {
          origin: `${start.lat},${start.lng}`,
          destination: `${destination.lat},${destination.lng}`,
          transportMode: "car",
          return: "polyline",
        };
        // Call the routing service with the defined parameters
        router.calculateRoute(
          routingParams,
          routeResponseHandler,
          console.error
        );
      }
      map.current.addEventListener("tap", (evt) => {
        var coords = map.current.screenToGeo(
          evt.currentPointer.viewportX,
          evt.currentPointer.viewportY
        );
        calculateRoute(
          platform.current,
          map.current,
          group.current,
          userPosition,
          coords
        );
      });
      if (destinyPosition && userPosition) {
        calculateRoute(
          platform.current,
          map.current,
          group.current,
          userPosition,
          { lat: destinyPosition.lat, lng: destinyPosition.lng }
        );
      }
    },
    // Dependencies array
    [destinyPosition, userPosition, permission]
  );

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 480,
        md: 768,
        lg: 1080,
        xl: 1536,
      },
    },
  });

  return  <ThemeProvider theme={theme}>
  <Box sx={{
    height: {
      xs: '77vh',
      sm: '77vh',
      md: '79vh',
      lg :'79vh'
    },
    width: '100%',
    position: 'absolute'
  }} id="map" ref={mapRef} />
  </ThemeProvider>
};

export default HereMap;

// Return a div element to hold the map
